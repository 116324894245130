"use client";

import { LoyaltyAccount } from "@/generated/email_parser.openapi";
import {
  AdditionalRateResponseV3,
  HotelDetails,
  HotelDetailsV3,
  HotelReviewItem,
  RawRateSummary,
  Review,
  RoomSummary,
  RoomSummaryV3,
} from "@/generated/search.openapi";

import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";

export type BookingInfo = {
  checkinStr: string;
  checkoutStr: string;
  numberOfAdults: number;
  childrenAges: number[];
  room: RoomSummaryV3 | RoomSummary;
  rate: RawRateSummary;
  bookingToken: string;
};

type ReviewDetails = {
  review: (HotelReviewItem | Review)[];
};

type BookingContextType = {
  hotelDetails?: HotelDetailsV3 | HotelDetails;
  setHotelDetails?: Dispatch<
    SetStateAction<HotelDetailsV3 | HotelDetails | undefined>
  >;
  bookingInfo?: BookingInfo;
  setBookingInfo?: Dispatch<SetStateAction<BookingInfo | undefined>>;
  bookingInfoLoading?: boolean;
  setBookingInfoLoading?: Dispatch<SetStateAction<boolean>>;
  reviewDetails?: ReviewDetails;
  setReviewDetails?: Dispatch<SetStateAction<ReviewDetails | undefined>>;
  selectedLoyaltyAccount?: LoyaltyAccount;
  setSelectedLoyaltyAccount?: Dispatch<
    SetStateAction<LoyaltyAccount | undefined>
  >;
  rateResponse?: AdditionalRateResponseV3;
  setRateResponse?: Dispatch<
    SetStateAction<AdditionalRateResponseV3 | undefined>
  >;
};

const BookingContext = createContext<BookingContextType>({});

export default function BookingProvider({ children }: PropsWithChildren) {
  const [hotelDetails, setHotelDetails] = useState<
    HotelDetailsV3 | HotelDetails
  >();
  const [bookingInfo, setBookingInfo] = useState<BookingInfo>();
  const [reviewDetails, setReviewDetails] = useState<ReviewDetails>();
  const [bookingInfoLoading, setBookingInfoLoading] = useState<boolean>(false);
  const [selectedLoyaltyAccount, setSelectedLoyaltyAccount] =
    useState<LoyaltyAccount>();
  const [rateResponse, setRateResponse] = useState<AdditionalRateResponseV3>();
  return (
    <BookingContext.Provider
      value={{
        hotelDetails,
        setHotelDetails,
        bookingInfo,
        setBookingInfo,
        reviewDetails,
        setReviewDetails,
        bookingInfoLoading,
        setBookingInfoLoading,
        selectedLoyaltyAccount,
        setSelectedLoyaltyAccount,
        rateResponse,
        setRateResponse,
      }}
    >
      {children}
    </BookingContext.Provider>
  );
}

const useBooking = () => {
  const {
    hotelDetails,
    setHotelDetails,
    bookingInfo,
    setBookingInfo,
    reviewDetails,
    setReviewDetails,
    bookingInfoLoading,
    setBookingInfoLoading,
    selectedLoyaltyAccount,
    setSelectedLoyaltyAccount,
    rateResponse,
    setRateResponse,
  } = useContext(BookingContext);
  return {
    hotelDetails,
    setHotelDetails,
    bookingInfo,
    setBookingInfo,
    reviewDetails,
    setReviewDetails,
    bookingInfoLoading,
    setBookingInfoLoading,
    selectedLoyaltyAccount,
    setSelectedLoyaltyAccount,
    rateResponse,
    setRateResponse,
  };
};

export { useBooking };
